import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_risk_flag = _resolveComponent("risk-flag")!
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    id: "inventory-category-adjustments",
    full: "",
    title: _ctx.getTitleCaseTranslation('core.domain.inventory'),
    "go-back": _ctx.goBack
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": "",
        "bold-title": "",
        "side-pane-options": {
                hideHeader: true,
            }
      }, {
        action: _withCtx((row) => [
          _createVNode(_component_risk_flag, {
            "flag-color": row.item.fromSort ? _ctx.RiskFlagEnum.Orange : _ctx.RiskFlagEnum.NoFlag,
            title: "From Sort"
          }, null, 8, ["flag-color"])
        ]),
        "cell(dateTime)": _withCtx((row) => [
          _createVNode(_component_simple_date_popover, {
            date: row.item.dateTime,
            "show-time": true
          }, null, 8, ["date"])
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}