
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import router from '@/router';
import { formatDateUI } from '@/functions/date';
import { TableDefinition } from '@/types';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import InventoryService from '@/services/InventoryService';
import InventoryAdjustmentHistoryDTO from '@/dtos/InventoryAdjustmentHistoryDTO';
import RiskFlag from '@/components/RiskFlag.vue';
import RiskFlagEnum from '@/domain/enums/RiskFlag';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';

type State = {
    loading: boolean;
    adjustments: Array<InventoryAdjustmentHistoryDTO>;
};

export default defineComponent({
    name: 'inventory-adjustment-list',
    components: {
        BAdvancedTable,
        Screen,
        RiskFlag,
        SimpleDatePopover,
    },
    props: {
        locationId: { type: Number, default: 0 },
        itemId: { type: Number, default: 0 },
        inventoryCategoryId: { type: Number, default: 0 },
        title: { type: String, default: '' },
        adjustForSort: { type: Number, default: 0 },
    },
    setup(props) {
        const inventoryService = new InventoryService();
        const state = reactive<State>({
            loading: true,
            adjustments: [],
        });

        onBeforeMount(async () => {
            const getAdjustments = await inventoryService.getAllInventoryAdjustmentsByLocationItemAndCategory(
                props.locationId,
                props.itemId,
                props.inventoryCategoryId,
                Boolean(Number(props.adjustForSort)),
            );
            state.adjustments = getAdjustments.inventory;
            state.loading = false;
        });

        const table = computed(
            (): TableDefinition<InventoryAdjustmentHistoryDTO> => ({
                items: state.adjustments,
                key: 'inventoryAdjustmentList',
                name: props.title,
                columnDefinition: [
                    { key: 'transactionIdView', label: getTitleCaseTranslation('core.domain.transactionId'), searchable: true },
                    { key: 'fromLocationName', label: getTitleCaseTranslation('core.domain.fromLocation'), searchable: true },
                    { key: 'toLocationName', label: getTitleCaseTranslation('core.domain.toLocation'), searchable: true },
                    { key: 'fromCategoryName', label: getTitleCaseTranslation('core.domain.fromCategory'), searchable: true },
                    { key: 'toCategoryName', label: getTitleCaseTranslation('core.domain.toCategory'), searchable: true },
                    { key: 'quantity', label: getTitleCaseTranslation('core.domain.quantity'), searchable: true },
                    { key: 'username', label: getTitleCaseTranslation('core.domain.username'), searchable: true },
                    { key: 'dateTime', label: getTitleCaseTranslation('core.domain.dateAndTime') },
                ],
            }),
        );

        async function goBack() {
            router.back();
        }

        return {
            state,
            formatDateUI,
            table,
            getTitleCaseTranslation,
            goBack,
            props,
            RiskFlagEnum,
        };
    },
});
